import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

import {
  Section,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopHeader from "../components/TopHeader"
import ProductSection from "../sections/Product/ProductSection"
import ProductForm from "../sections/Product/ProductForm"
import QuotePopup from "../components/QuotePopup"

function ProductSubCategoryPage({ data, intl }) {
  const pageData = data.contentfulProductSubCategoryLanding
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  const [isSticky, setSticky] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  useEffect(() => {
    const pageStartPoint = document
      .getElementById("page-start")
      .getBoundingClientRect().top
    const startOffset = document
      .getElementById("sticky-top")
      .getBoundingClientRect().top
    const endOffsset = document
      .getElementById("sticky-end")
      .getBoundingClientRect().top
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (
        startOffset - pageStartPoint < currentScrollY &&
        currentScrollY < endOffsset - pageStartPoint
      ) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  const productSectionData = {
    title: pageData.productSectionTitle,
    description: pageData.productSectionDescription,
    products: data.allContentfulProduct.edges,
  }
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <div id="page-start" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/">
            {pageData.category.name} <BulletIcon />
          </Link>
          <span>{pageData.title}</span>
        </div>
      </BreadCrumb>
      <TopHeader
        topHeaderTitle={pageData.heroTitle}
        topHeaderText={pageData.heroDescription}
        topHeaderImg={pageData.heroImage}
      />
      <ProductSection
        intl={intl}
        data={productSectionData}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <Section pt="90px" pb="90px" bgColor="#F9F9F9">
        <div className="container">
          <MainPanel id="sticky-top">
            <LeftPannel>
              <MarkdownContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <div className={isSticky ? "sticky-wrapper" : "no-sticky"}>
                <ProductForm intl={intl} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <div id="sticky-end"></div>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(ProductSubCategoryPage)

export const query = graphql`
  query ProductSubCategoryPageQuery(
    $slug: String
    $locale: String
    $category: String
    $subCategory: String
  ) {
    contentfulProductSubCategoryLanding(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      node_locale
      metaTitle
      metaDescription
      title
      category {
        name
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(
      filter: {
        productCategory: { name: { eq: $category } }
        productSubcategory: { name: { eq: $subCategory } }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          url
          node_locale
          productSku
          productName
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
          }
          productSubcategory {
            name
          }
          productRoof {
            name
          }
          image {
            fluid(quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          width
          length
          height
          startingPrice
          rto
          financing
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          freeDelivery
        }
      }
    }
  }
`
