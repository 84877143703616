import React from "react"
import {
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"

const ProductSection = ({ intl, data, openQuoteModal }) => {
  return (
    <Section pt="90px" pb="90px" xpt="90px" xpb="60px" bgColor="#fff">
      <div className="container">
        <SectionTitle>{data.title}</SectionTitle>
        <SectionDescription maxWidth="1120px" mb="60px">
          <div
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </SectionDescription>
        <ProductCardList
          intl={intl}
          data={data.products}
          openQuoteModal={openQuoteModal}
        />
      </div>
    </Section>
  )
}

export default ProductSection
